import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { generate, Observable, range, throwError, of } from 'rxjs';
import { concatMap, catchError, retry, map } from 'rxjs/operators';
import { GoogleMap } from '@angular/google-maps';
import { provideRoutes } from '@angular/router';

export interface AddressProfile{
  id: number;
  route_id: string;
  pkid: number;
  query: string;
  formatted: string;
  location: {
    google: {
      lat: number;
      lng: number;
      accuracy_class: string;
      accuracy_diameter: number;
      accuracy_results_returned: number;
      confidence: any;
    };
    user_selected: any;
    tracking: any;
    postal_code: {
      lat: number;
      lng: number;
      accuracy_diameter: number;
    };
    fsa: {
      lat: number;
      lng: number;
      accuracy_diameter: number;
    };
    city: {
      lat: number;
      lng: number;
      accuracy_diameter: number;
    };
  };
};

export interface AddressProfileResponse{
  status: string;
  results: AddressProfile[];
}

@Injectable()
export class AppService {
  url = "http://54.70.38.49:8000"
  suggestionId: number = 0;
  suggestionToken: google.maps.places.AutocompleteSessionToken = null;

  constructor(private http: HttpClient) { }

  /*
  getAddressData(): Observable<AddressProfileResponse> {
    var request_url = `${this.url}/address/invalid`;

    return this.http.get<AddressProfileResponse>(request_url);
  }
  */

  getAddressData(): Observable<AddressProfile> {
    var request_url = `${this.url}/address/invalid`;
    
    var observable = new Observable<AddressProfile>((observer: any) => {
      this.http.get<AddressProfileResponse>(request_url).subscribe((addresses: AddressProfileResponse) => {
        addresses.results.forEach((address) => {
          var geocode_url = `${this.url}/address/geocode?id=${address.id}&query=${encodeURIComponent(address.query)}`;

          this.http.get<any>(geocode_url).subscribe((geocode) =>{
            address.formatted = geocode.results.formatted;
            address.location = geocode.results.location;
            observer.next(address);
          })
        });
      });
    });

    return observable;
  }

  updateAddress(addressId: number, route_id: string, pkid: number, lat: number, lng: number): Observable<any>{
    var request_url = `${this.url}/address/validate/${addressId}/${route_id}/${pkid}/${lat}/${lng}`;

    var observable = new Observable<any>((observer: any) => {
      this.http.get<any>(request_url).subscribe((response) => {
        console.log(response);
      });
    });

    return observable;
  }

  reverseGeocode(lat:number, lng:number): Observable<google.maps.GeocoderResult>{
    var service = new google.maps.Geocoder()
    var request = {location: {lat: lat, lng: lng}};

    var observable = new Observable<google.maps.GeocoderResult>((observer: any) => {
      service.geocode(request, (result, status) =>{
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(result[0]);
        }else{
          console.log(`Reverse geocoding failed [${status}]`);
        }
      });
      
    });

    return observable;
  }

  getSearchSuggestions(id: number, query: string, origin: google.maps.LatLngLiteral, boundsCenter: google.maps.LatLngLiteral, boundsRadius: number): Observable<google.maps.places.QueryAutocompletePrediction[]>{
    var service = new google.maps.places.AutocompleteService();
    var request: google.maps.places.AutocompletionRequest = {
      sessionToken: this.suggestionToken,
      input: query,
      origin: new google.maps.LatLng(origin),
      location: new google.maps.LatLng(boundsCenter),
      radius: boundsRadius,
    };

    if(id != this.suggestionId){
      this.suggestionId = id;
      this.suggestionToken = new google.maps.places.AutocompleteSessionToken();
      request.sessionToken = this.suggestionToken;
    }
    console.log(this.suggestionToken);

    var observable = new Observable<google.maps.places.QueryAutocompletePrediction[]>((observer: any) => {
      service.getPlacePredictions(request, (results, status) => {
        if(status == google.maps.places.PlacesServiceStatus.OK){
          observer.next(results);
        }else{
          console.log(`Search suggestions failed [${status}]`);
        }
      });
    });

    return observable;
  }

  geocodePlace(placeId: string):  Observable<google.maps.GeocoderResult>{
    var service = new google.maps.Geocoder()
    var request: google.maps.GeocoderRequest = {placeId: placeId};

    var observable = new Observable<google.maps.GeocoderResult>((observer: any) => {
      service.geocode(request, (result, status) =>{
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(result[0]);
        }else{
          console.log(`Geocoding place failed [${status}]`);
        }
      });
    });

    return observable;
  }
}