import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, Subscription } from 'rxjs';

import { AppService, AddressProfileResponse, AddressProfile } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [AppService]
})
export class AppComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap

  title = 'Address Correction';
  optimizationId = 1855;
  subscription:Subscription = null;
  addresses: AddressProfile[] = [];
  filteredAddresses: AddressProfile[] = []; 
  selectedAddress: AddressProfile = null;
  searchSuggestions = [];
  selectedSearch = null;
  showDetails = false;
  filterResults = 1;
  
  progressValue = 0
  progressRecordsLoaded = 0
  progressTotalRecords = 0

  formattedAddresses = {
    user_selected: "",
    google: "",
    search: "",
    tracking: ""
  };

  mapOptions: google.maps.MapOptions = {
    center: {lat: 43.7084763, lng: -79.621399},
    zoom: 9,
    zoomControl: false,
    fullscreenControl: false
  };

  readonly USER_SELECTED_ICON: string = "./assets/yellow-pin.svg";
  readonly GOOGLE_ICON: string = "./assets/red-pin.svg";
  readonly TRACKING_ICON: string = "./assets/blue-pin.svg";
  readonly SEARCH_ICON: string = "./assets/white-pin.svg";
  readonly NO_PIN_ICON: string = "./assets/no-pin.svg";
  readonly ICON_SIZE = new google.maps.Size(40, 40);

  searchMarker: google.maps.MarkerOptions = null;
  markers:google.maps.MarkerOptions[] = [];
  circles:any[] = [];

  constructor(private snackBar: MatSnackBar, private appService: AppService){}

  ngOnInit(){
    if(this.subscription != null)
      this.subscription.unsubscribe()

    this.addresses = [];
    this.filteredAddresses = [];

    this.subscription = this.appService.getAddressData().subscribe((address: AddressProfile) => {
      this.addresses = this.addresses.concat(address)
      this.filteredAddresses = this.addresses

      /*
      this.addresses = response.results;
      this.addresses.sort((a: AddressProfile, b: AddressProfile) => a.id - b.id)
      this.filteredAddresses = this.addresses
      */
      /*
      this.addresses = this.addresses.concat(page.records);
      this.filteredAddresses = this.filteredAddresses.concat(page.records.filter((record, idx, arr) => this.filterResults == 0 || record.status != 'ok'));
      this.filteredAddresses.sort((a: Address, b: Address) => a.result.id - b.result.id)

      this.progressRecordsLoaded = page.record_offset + page.records.length
      this.progressTotalRecords = page.record_count
      this.progressValue = this.progressRecordsLoaded / this.progressTotalRecords * 100
      */
    });
  }

  applyFilter(){
    //this.filteredAddresses = this.addresses.filter((record, idx, arr) => this.filterResults == 0 || record.status != 'ok');
  }

  search(event){
    var token = this.selectedAddress.id;
    var query = event.srcElement.value;
    var origin = this.selectedAddress.location.postal_code;
    var boundsCenter = this.selectedAddress.location.city;
    var boundsRadius = this.selectedAddress.location.city.accuracy_diameter * 1000;

    this.appService.getSearchSuggestions(token, query, origin, boundsCenter, boundsRadius).subscribe((results) => {
      this.searchSuggestions = results;
    });
  }

  setSearchLocation(event){
    this.appService.geocodePlace(event.option.value).subscribe((results) => {
      this.selectedSearch = results;
      this.formattedAddresses.search = results.formatted_address;
      
      if(this.searchMarker != null)
        this.markers.splice(this.markers.indexOf(this.searchMarker), 1);

      this.searchMarker = {
        position: results.geometry.location,
        icon: {url: this.SEARCH_ICON, scaledSize: this.ICON_SIZE}
      }
      this.markers.push(this.searchMarker);

      this.recenterMap();
    });
  }

  selectAddress(row: AddressProfile){
    this.selectedAddress = row;
    this.selectedSearch = null;
    this.showDetails = true;

    this.appService.reverseGeocode(row.location.google.lat, row.location.google.lng).subscribe((result) => {
      this.formattedAddresses.google = result.formatted_address;
    });
    this.formattedAddresses.search = row.query;
    this.formattedAddresses.tracking = ""

    this.markers = [{
      position: row.location.google,
      icon: {url: this.GOOGLE_ICON, scaledSize: this.ICON_SIZE}
    }];

    if(row.location.user_selected != null){
      this.appService.reverseGeocode(row.location.user_selected.lat, row.location.user_selected.lng).subscribe((result) => {
        this.formattedAddresses.user_selected = result.formatted_address;
      });

      this.markers.push({
        position: row.location.user_selected,
        icon: {url: this.USER_SELECTED_ICON, scaledSize: this.ICON_SIZE}
      });
    }

    if(row.location.tracking != null){
      this.appService.reverseGeocode(row.location.tracking.lat, row.location.tracking.lng).subscribe((result) => {
        this.formattedAddresses.tracking = result.formatted_address;
      });

      this.markers.push({
        position: row.location.tracking,
        icon: {url: this.TRACKING_ICON, scaledSize: this.ICON_SIZE}
      });
    }

    this.circles = [{
        center: row.location.postal_code,
        radius: row.location.postal_code.accuracy_diameter * 1000,
        fillColor: "red",
        fillOpacity: 0.15,
        strokeColor: "red",
        srokeWeight: 5,
        clickable: false
      },
      {
        center: row.location.fsa,
        radius: row.location.fsa.accuracy_diameter * 1000,
        fillColor: "white",
        fillOpacity: 0.35,
        strokeColor: "white",
        srokeWeight: 15,
        clickable: false
      },
      /*{
        center: row.result.location.city,
        radius: row.result.location.city.accuracy_diameter * 1000,
        fillColor: "red",
        fillOpacity: 0.2,
        strokeColor: "red",
        srokeWeight: 3
      }*/
    ];
    this.recenterMap();
  }

  selectLocation(selection:string){
    var location: any = this.selectedAddress.location.user_selected;
    switch(selection){
      case 'google': location = this.selectedAddress.location.google; break;
      case 'user_selected': location = this.selectedAddress.location.user_selected; break;
      case 'tracking': location = this.selectedAddress.location.tracking; break;
      case 'search': location = {lat: this.selectedSearch.geometry.location.lat(), lng: this.selectedSearch.geometry.location.lng()}; break;
      case 'none': location = {lat: 0, lng: 0}; break;
    }
    
    this.appService.updateAddress(this.selectedAddress.id, this.selectedAddress.route_id, this.selectedAddress.pkid, location.lat, location.lng)
    .subscribe((result) => {
      console.log(result);
    });


    this.filteredAddresses = this.filteredAddresses.filter((item) => item != this.selectedAddress)
    this.snackBar.openFromComponent(SnackBarLocationUpdatedComponent, {panelClass: ["snack-bar"], duration: 2500});

    this.showDetails = false;
    this.filterResults = 0;
    this.selectedSearch = null;
    this.searchSuggestions = [];

    this.searchMarker = null;
    this.markers = [];
    this.circles = [];
  }

  recenterMap(){
    var locations = this.selectedAddress.location;
    var lats = [locations.google.lat, locations.postal_code.lat];
    var lngs = [locations.google.lng, locations.postal_code.lng];
    
    if(this.selectedAddress.location.user_selected != null){
      lats.push(locations.user_selected.lat);
      lngs.push(locations.user_selected.lng);
    }
    
    if(this.selectedAddress.location.tracking != null){
      lats.push(locations.tracking.lat);
      lngs.push(locations.tracking.lng);
    }

    if(this.selectedSearch != null){
      lats.push(this.selectedSearch.geometry.location.lat());
      lngs.push(this.selectedSearch.geometry.location.lng());
    }

    var bounds = {
      south: Math.min(...lats) - 0.001,
      west: Math.min(...lngs) - 0.001,
      north: Math.max(...lats) + 0.001,
      east: Math.max(...lngs) + 0.001
    }
    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);
  }
}

@Component({
  selector: 'snack-bar-location-updated',
  template: '<div>Address location updated</div>',
  styles: [`
    div{
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }`],
})
export class SnackBarLocationUpdatedComponent {}
