import {NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  imports: [MatSidenavModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatTableModule, MatRadioModule, MatAutocompleteModule, MatSnackBarModule, MatProgressBarModule],
  exports: [MatSidenavModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatTableModule, MatRadioModule, MatAutocompleteModule, MatSnackBarModule, MatProgressBarModule],
})
export class MaterialModule { }